.contenitoreStoria .tns-ovh {
  overflow: visible !important;
}

.contenitoreStoria .tns-controls {
  text-align: right;
}

.timelineStoria .bollino {
  background-color: white;
}

.timelineStoria .bollino.selected {
  background-color: #294484;
}

.timelineStoria .bollino2 {
  left: auto;
  margin-left: 0;
}

.timelineStoria .bollino2.selected {
  left: 0;
  margin-left: -117px;
}

.storiaSlide .slide-item.selected {
  background-color: #f9b101;
}

.storiaSlide .slide-item.selected .anno {
  color: #294484;
}

.arrows-centered .tns-controls {
  display: flex;
  justify-content: center;
}

.w-max {
  width: max-content;
}

.docentimodal a {
  font-size: 1rem;
  text-decoration: underline;
  color: #f9b101;
  margin-bottom: 5px;
  display: inline-block;
}

.docentimodal .swal2-content {
  padding: 0;
}

@media (max-width: 640px) {
  .sameheight:not(.forced) {
    height: auto !important;
  }
}
.vimeo {
  margin: 0 auto;
  width: 50%;
}
.imagevideo {
  margin: 0 auto;
  width: 50%;
}
.btn-play {
  font-size: 80px;
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  transition: 300ms;
}
.vimeo:hover .btn-play {
  height: 100px;
  width: 100px;
}

@media (max-width: 1200px) {
  .imagevideo {
    margin: 0 auto;
    width: 100%;
  }
  .vimeo {
    margin: 0 auto;
    width: 100%;
  }
}
.wamessage {
  z-index: 10;
  cursor: pointer;
  color: #fff;
  background-color: #25d366;
  border: 1px solid #25d366;
  border-radius: 3rem;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  transition: 300ms;
}
.wamessage:hover {
  color: #25d366;
  background-color: #fff;
  border: 1px solid #25d366;
}
.wamessage2 {
  cursor: pointer;
  color: #fff;
  background-color: #25d366;
  border: 1px solid #25d366;
  border-radius: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  transition: 300ms;
  width: fit-content;
}
.wamessage2:hover {
  color: #25d366;
  background-color: #fff;
  border: 1px solid #25d366;
}
