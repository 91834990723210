#menu-display {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
}

#menu-display span.testo {
    font-size: 0.875rem;
}

.menuToggle
{
    display: block;
    z-index: 999;
    right: 0;
    top: 9px;
    width: 100%;
    height: auto;
    margin-bottom: 5px;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    position: relative;
    float: right;
    border-radius: 50%;
    padding-top: 0;
}

.menuToggle input
{
    display: block;
    width: 100%;
    height: 40px;
    position: absolute;
    top: -5px;
    left: 0;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
    cursor: pointer;
}

.menuToggle span
{
    display: block;
    width: 39px;
    height: 2px;
    margin: 0 auto 7px;
    position: relative;
    background: white;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
}

.menuToggle span:first-child
{
    transform-origin: 0% 0%;
}

.menuToggle span:nth-last-child(1)
{
    transform-origin: 0% 100%;
}

.menuToggle input:checked ~ span
{
    opacity: 1;
    transform: rotate(45deg) translate(3px, -4px);
    background: white;
}

.menuToggle input:checked ~ span:nth-last-child(2)
{
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

.menuToggle input:checked ~ span:nth-last-child(1)
{
    transform: rotate(-45deg) translate(1px, 9px);
}

.menuToggle.reset input:checked ~ span {
    opacity: 1;
    transform: none;
}