.nav-header-top span, .nav-header-top a {
    position: relative;
}
.nav-header-top span::before {
    position: absolute;
    top: 0;
    left: calc(50% - 12px);
    content: url('../images/simbolo.png');
    z-index: 10;
}
.nav-header-top a::before {
    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
    position: absolute;
    top: 0;
    left: calc(50% - 50px);
    content: url('../images/simbolo.png');
    z-index: 10;
    opacity: 0;
}
.nav-header-top a:hover::before, .nav-header-top a.selected::before {
    opacity: 1;
    left: calc(50% - 12px);
}
.headerTopBarSlide {
    position: relative;
    max-height: 825px;
}
.headerTopBarSlide .tns-item picture {
    display: flex;
    justify-content: center;
}
.headerSlide .tns-nav {
    display: none;
}