.menu-mobile {
  padding: 0 !important;
  flex-direction: $flex-direction-column;
  justify-content: $flex-start;
}

.menu-mobile .swal2-popup {
  flex-direction: $flex-direction-column;
  justify-content: $flex-start;
}

.testataMobile .logo-mobile {
  max-width: 200px;
}

.testataMobile a::after {
  display: none;
}

.elencoVociMenuMobile nav {
  display: $d-flex;
  flex-direction: $flex-direction-column;
}

.elencoVociMenuMobile nav ul {
  margin: 1rem 0;
}

.elencoVociMenuMobile nav ul li {
  flex-basis: 100%;
  justify-content: $flex-start;
  align-items: $flex-start;
  display: $d-flex;
  flex-direction: $flex-direction-column;
  padding: 0;
  margin: 0 0;
  position: relative;
}

.elencoVociMenuMobile nav ul li::after {
  font-family: $fa-font-family;
  content: fa-content($fa-var-chevron-right);
  position: absolute;
  right: calc(5%);
  top: 10px;
  @include transition(200);
}

.elencoVociMenuMobile nav ul li a,
.elencoVociMenuMobile nav ul li span,
.menu-sub-row {
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 0.5rem 0.5rem 0.5rem;
}

.elencoVociMenuMobile nav ul li.selected::after {
  @include transform(rotate(90deg));
}

.nav-header-bottom ul.subnavMobile {
  display: none !important;
  padding: 0 2rem;
  margin: 0.5rem 0 2rem 0;
  @include transition(200);
}

.nav-header-bottom ul.subnavMobile.selected {
  display: block !important;
}

.elencoVociMenuMobile nav ul li.spinnerMobile::after {
  display: none !important;
}

.menuCategorieMobile {
  background-color: #c8d8df;
  padding: 1rem !important;
  border-radius: 10px;
  margin-top: 0 !important;
}

.titoloCatalogo {
  margin-bottom: 0 !important;
}

.titoloCatalogo::after {
  display: none !important;
}

.menu-sub-row {
  cursor: pointer;
}

.nav-header-bottom ul.subSubNavMobile {
  padding: 0 2rem;
}

.swal2-close:hover {
  transform: none;
  background: 0 0;
  color: #294484;
}

.swal2-close:focus {
  outline: none;
}
